import NotFound from '@/views/404'

export const routes = [
    {
        path: '/404',
        name: 'NOT_FOUND',
        component: NotFound,
        meta: { fullScreen: true },
    },
    {
        // Keep the catchAll route last
        path: '/:pathMatch(.*)*',
        redirect: { name: 'NOT_FOUND' },
    },
]
