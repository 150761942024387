import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'

import App from './App'

import messages from './i18n'
import router from './router'

const app = createApp(App)

const i18n = new createI18n({
    fallbackLocale: 'en',
    locale: 'en',
    messages,
    legacy: false,
})
app.use(i18n)
app.use(router)

app.mount('#app')
