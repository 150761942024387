<template>
    <div class="not-found align-center justify-content-center">
        <img class="logo" src="@/assets/moat-logo.png" alt="Moat (Logo)" />
        <hr />
        <h1 class="mt-3 extra-large">
            {{ t('notFound.headerPrimary') }}
        </h1>
        <p class="my-3 knights-cloak--text large">
            {{ t('notFound.headerSecondary') }}
        </p>
        <p>{{ t('notFound.bodyText') }}</p>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<style lang="scss">
.not-found {
    display: flex;
    height: 100vh;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .logo {
        width: 50px;
    }
}
</style>
